<template>
  <div>
    <section v-if="!loading" class="d-block mt-50">
      <div class="container">
        <div class="d-lg-flex d-block justify-content-between mt-65 mb-45">
          <div>
            <h2 class="text-primary">
              Group: {{ group.group_name }}
            </h2>
          </div>
          <div>
          </div>
        </div>
        <div class="bg-white py-40">
          <div class="row no-gutters p-20 p-lg-0">
            <div class="col-lg-7 col-md-12 m-auto">
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Country</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.country_name" class="admin-input form-control" disabled
                           placeholder="Your country"
                           type="text">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Region</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.region_name" class="admin-input form-control" disabled
                           name="name" placeholder="Your group" type="text">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Group</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.group_name" class="admin-input form-control" disabled
                           name="name" placeholder="Your group" type="text">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Municipality</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.municipality_name" class="admin-input form-control" disabled
                           name="name" placeholder="Your municipality" type="text">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Group GPS</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.group_gps" class="admin-input form-control" placeholder="Type group GPS"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.group_gps.required"
                      class="error">
                      Group GPS is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Variety</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.variety" class="admin-input form-control" placeholder="Type variety"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.variety.required"
                      class="error">
                      Variety is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Altitude Min</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.altitude_min" class="admin-input form-control"
                           placeholder="Type altitude min" type="text">
                    <div
                      v-if="$v.$error & !$v.group.altitude_min.required"
                      class="error">
                      Altitude min is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Altitude Max</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.altitude_max" class="admin-input form-control"
                           placeholder="Type altitude max" type="text">
                    <div
                      v-if="$v.$error & !$v.group.altitude_max.required"
                      class="error">
                      Altitude max is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Main Start</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.main_start_date" class="admin-input form-control"
                           placeholder="Type main start"
                           type="date">
                    <div
                      v-if="$v.$error & !$v.group.main_start_date.required"
                      class="error">
                      Main start is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Main End</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.main_end_date" class="admin-input form-control" placeholder="Type main end"
                           type="date">
                    <div
                      v-if="$v.$error & !$v.group.main_end_date.required"
                      class="error">
                      Main end is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Fly Start</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.fly_start_date" class="admin-input form-control" placeholder="Type fly start"
                           type="date">
                    <div
                      v-if="$v.$error & !$v.group.fly_start_date.required"
                      class="error">
                      Fly start is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Fly End</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.fly_end_date" class="admin-input form-control" placeholder="Type fly end"
                           type="date">
                    <div
                      v-if="$v.$error & !$v.group.fly_end_date.required"
                      class="error">
                      Fly end is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Farmers total</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.farmers_total" class="admin-input form-control"
                           placeholder="Type farmers total"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.farmers_total.required"
                      class="error">
                      Farmers total is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Farmers age avg</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.farmers_age_avg" class="admin-input form-control"
                           placeholder="Type farmers age avg"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.farmers_age_avg.required"
                      class="error">
                      Farmers total is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Farmers youth share</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.farmers_youth_share" class="admin-input form-control"
                           placeholder="Type farmers youth share"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.farmers_youth_share.required"
                      class="error">
                      Farmers youth share is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Farmers female share</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.farmers_female_share" class="admin-input form-control"
                           placeholder="Type farmers female share"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.farmers_female_share.required"
                      class="error">
                      Farmers female share is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Coffee area total</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.coffee_area_total" class="admin-input form-control"
                           placeholder="Type Coffee area total"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.coffee_area_total.required"
                      class="error">
                      Coffee area total is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Coffee area avg</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.coffee_area_avg" class="admin-input form-control"
                           placeholder="Type Coffee area avg"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.coffee_area_avg.required"
                      class="error">
                      Coffee area avg is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Coffee area min</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.coffee_area_min" class="admin-input form-control"
                           placeholder="Type Coffee area min"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.coffee_area_min.required"
                      class="error">
                      Coffee area min is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Coffee area max</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.coffee_area_max" class="admin-input form-control"
                           placeholder="Type Coffee area max"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.coffee_area_max.required"
                      class="error">
                      Coffee area max is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">GBE total</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.gbe_total" class="admin-input form-control" placeholder="Type GBE total"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.gbe_total.required"
                      class="error">
                      GBE total is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Productivity avg</label>
                  </div>
                  <div class="col-12 col-md-8 col-lg-8 col-sm-12">
                    <input v-model="group.productivity_avg" class="admin-input form-control"
                           placeholder="Type productivity avg"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.productivity_avg.required"
                      class="error">
                      Productivity avg is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group p-3 border rounded">
                <h4>Coffee Species</h4>
                <div v-for="(coffee_spec, _index) in group.coffee_species" :key="_index" class="form-row mb-30">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Name</label>
                  </div>
                  <div class="col-12 col-md-6 col-lg-6 col-sm-12">
                    <input v-model="coffee_spec.name" class="admin-input form-control" placeholder="Type name"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.coffee_species.$each.$iter[_index].name.required"
                      class="error">
                      Name is required.
                    </div>
                  </div>
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <div class="d-inline-flex float-right">
                      <button
                        class="btn btn-add-success float-right"
                        @click="removeChildObject(group.coffee_species, _index)">
                        <i aria-hidden="true" class="fa fa-times"></i> Close
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                    <div class="text-right">
                      <div class="d-inline-flex">
                        <button class="btn btn-add" type="button" @click="addMore(group.coffee_species)">
                          <i aria-hidden="true" class="fa fa-plus"></i> Add More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group p-3 border rounded">
                <h4>Harvest Methods</h4>
                <div v-for="(harvest_method, _index) in group.harvest_methods" :key="_index" class="form-row mb-30">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Name</label>
                  </div>
                  <div class="col-12 col-md-6 col-lg-6 col-sm-12">
                    <input v-model="harvest_method.name" class="admin-input form-control" placeholder="Type name"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.harvest_methods.$each.$iter[_index].name.required"
                      class="error">
                      Name is required.
                    </div>
                  </div>
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <div class="d-inline-flex float-right">
                      <button
                        class="btn btn-add-success float-right"
                        @click="removeChildObject(group.harvest_methods, _index)">
                        <i aria-hidden="true" class="fa fa-times"></i> Close
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                    <div class="text-right">
                      <div class="d-inline-flex">
                        <button class="btn btn-add" type="button" @click="addMore(group.harvest_methods)">
                          <i aria-hidden="true" class="fa fa-plus"></i> Add More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group p-3 border rounded">
                <h4>Processing Methods</h4>
                <div v-for="(processing_method, _index) in group.processing_methods" :key="_index"
                     class="form-row mb-30">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Name</label>
                  </div>
                  <div class="col-12 col-md-6 col-lg-6 col-sm-12">
                    <input v-model="processing_method.name" class="admin-input form-control" placeholder="Type name"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.processing_methods.$each.$iter[_index].name.required"
                      class="error">
                      Name is required.
                    </div>
                  </div>
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <div class="d-inline-flex float-right">
                      <button
                        class="btn btn-add-success float-right"
                        @click="removeChildObject(group.processing_methods, _index)">
                        <i aria-hidden="true" class="fa fa-times"></i> Close
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                    <div class="text-right">
                      <div class="d-inline-flex">
                        <button class="btn btn-add" type="button" @click="addMore(group.processing_methods)">
                          <i aria-hidden="true" class="fa fa-plus"></i> Add More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group p-3 border rounded">
                <h4>Drying Methods</h4>
                <div v-for="(drying_method, _index) in group.drying_methods" :key="_index" class="form-row mb-30">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Name</label>
                  </div>
                  <div class="col-12 col-md-6 col-lg-6 col-sm-12">
                    <input v-model="drying_method.name" class="admin-input form-control" placeholder="Type name"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.drying_methods.$each.$iter[_index].name.required"
                      class="error">
                      Name is required.
                    </div>
                  </div>
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <div class="d-inline-flex float-right">
                      <button
                        class="btn btn-add-success float-right"
                        @click="removeChildObject(group.drying_methods, _index)">
                        <i aria-hidden="true" class="fa fa-times"></i> Close
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                    <div class="text-right">
                      <div class="d-inline-flex">
                        <button class="btn btn-add" type="button" @click="addMore(group.drying_methods)">
                          <i aria-hidden="true" class="fa fa-plus"></i> Add More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group p-3 border rounded">
                <h4>Qualities</h4>
                <div v-for="(quality, _index) in group.qualities" :key="_index" class="form-row mb-30">
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <label class="col-form-label">Name</label>
                  </div>
                  <div class="col-12 col-md-6 col-lg-6 col-sm-12">
                    <input v-model="quality.name" class="admin-input form-control" placeholder="Type name"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.group.qualities.$each.$iter[_index].name.required"
                      class="error">
                      Name is required.
                    </div>
                  </div>
                  <div class="col-12 col-md-3 col-lg-3 col-sm-12">
                    <div class="d-inline-flex float-right">
                      <button
                        class="btn btn-add-success float-right"
                        @click="removeChildObject(group.qualities, _index)">
                        <i aria-hidden="true" class="fa fa-times"></i> Close
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                    <div class="text-right">
                      <div class="d-inline-flex">
                        <button class="btn btn-add" type="button" @click="addMore(group.qualities)">
                          <i aria-hidden="true" class="fa fa-plus"></i> Add More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mt-30">
                <div class="row">
                  <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                    <div class="text-right mt-30">
                      <div class="d-inline-flex">
                        <button v-if="!isObserverUser" class="btn btn-submit" type="button" @click="saveGroup"> Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'GroupEditView',
  components: {},
  props: {},
  data () {
    return {
      loading: false,
      group: {
        country: '',
        country_name: '',
        municipality_name: '',
        coffee_species: [
          {
            id: null,
            name: ''
          }
        ],
        region: '',
        region_name: '',
        group: '',
        group_name: '',
        group_gps: '0,0',
        harvest_methods: [
          {
            id: null,
            name: ''
          }
        ],
        processing_methods: [
          {
            id: null,
            name: ''
          }
        ],
        variety: '',
        drying_methods: [
          {
            id: null,
            name: ''
          }
        ],
        qualities: [
          {
            id: null,
            name: ''
          }
        ],
        altitude_max: 0,
        altitude_min: 0,
        main_start_date: 0,
        main_end_date: 0,
        fly_start_date: 0,
        fly_end_date: 0,
        farmers_total: 0,
        farmers_age_avg: 0,
        farmers_youth_share: 0,
        farmers_female_share: 0,
        coffee_area_total: 0,
        coffee_area_avg: 0,
        coffee_area_min: 0,
        coffee_area_max: 0,
        gbe_total: 0,
        productivity_avg: 0
      },
      regionContents: {}
    }
  },
  validations: {
    group: {
      required,
      altitude_max: {
        required
      },
      altitude_min: {
        required
      },
      main_start_date: {
        required
      },
      main_end_date: {
        required
      },
      fly_start_date: {
        required
      },
      fly_end_date: {
        required
      },
      farmers_total: {
        required
      },
      farmers_age_avg: {
        required
      },
      farmers_youth_share: {
        required
      },
      farmers_female_share: {
        required
      },
      coffee_area_total: {
        required
      },
      coffee_area_avg: {
        required
      },
      coffee_area_min: {
        required
      },
      coffee_area_max: {
        required
      },
      gbe_total: {
        required
      },
      productivity_avg: {
        required
      },
      variety: {
        required
      },
      group_gps: {
        required
      },
      coffee_species: {
        $each: {
          name: {
            required
          }
        }
      },
      harvest_methods: {
        $each: {
          name: {
            required
          }
        }
      },
      processing_methods: {
        $each: {
          name: {
            required
          }
        }
      },
      drying_methods: {
        $each: {
          name: {
            required
          }
        }
      },
      qualities: {
        $each: {
          name: {
            required
          }
        }
      }
    }
  },
  created () {
    this.getGroupEditPageContent()
  },
  computed: {
    regionContent () {
      return this.$store.getters.getRegionFarmerGroupAllCountryGroupContents
    }
  },
  mounted () {

  },
  methods: {
    addMore (object) {
      object.push({
        id: null,
        name: ''
      })
    },
    removeChildObject (objects, index) {
      if (index > -1 && objects.length > index) {
        objects.splice(index, 1)
      }
    },
    async getGroupEditPageContent () {
      this.loading = true
      await this.$store.dispatch('getRegionFarmerGroupContentsFromServer', { id: this.$route.params.id })
      this.loading = false
    },
    async saveGroup () {
      const vm = this
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          title: 'Group: ' + vm.group.group_name,
          text: 'Please check the form and fix the errors.',
          type: 'error', // 'warn', 'error', 'success'
          duration: 5000
        })
      } else {
        const regionPromises = []
        const formData = this.$_.cloneDeep(vm.group)
        formData.main_start_date = moment(vm.group.main_start_date).valueOf()
        formData.main_end_date = moment(vm.group.main_end_date).valueOf()
        formData.fly_start_date = moment(vm.group.fly_start_date).valueOf()
        formData.fly_end_date = moment(vm.group.fly_end_date).valueOf()
        formData.country_id = vm.group.country
        formData.group_id = vm.group.group

        const response = await this.$store.dispatch('createOrUpdateRegionFarmerGroupContent', formData)
        regionPromises.push(response)
        Promise.all(regionPromises).then((values) => {
          const regionPromises = []
          values.map((item) => {
            Promise.all(regionPromises).then((bolResponses) => {
              this.$notify({
                title: 'Group: ' + formData.group_name,
                text: 'Group updated successfully.',
                type: 'success', // 'warn', 'error', 'success'
                duration: 5000
              })
              this.$forceUpdate()
            })
          })
        })
      }
    }
  },
  watch: {
    regionContent: {
      deep: true,
      handler (newValue) {
        this.group = _.cloneDeep(newValue)
        this.group.main_start_date = moment(newValue.main_start_date).format('YYYY-MM-DD')
        this.group.main_end_date = moment(newValue.main_end_date).format('YYYY-MM-DD')
        this.group.fly_start_date = moment(newValue.fly_start_date).format('YYYY-MM-DD')
        this.group.fly_end_date = moment(newValue.fly_end_date).format('YYYY-MM-DD')
      }
    }
  }
}
</script>

<style scoped>

</style>
